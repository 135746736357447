@import '../common-variables.scss';

.location-list {
    color: var(--color-primary);

    .dropdown-list {
        margin: 20px 0;
        position: relative;

        @media screen and (min-width: 767px) {
            margin: 15px 0;
        }

        .title {
            font-family: Poppins-SemiBold, sans-serif;
            display: block;
            margin: 0 0 25px 0;
            font-size: 16px;
            font-weight: bold;
            border-bottom: 1px solid var(--color-primary);
            padding-bottom: 25px;
            padding-left: 0;
            cursor: pointer;

            @media screen and (min-width: 767px) {
                font-size: 14px;
                padding-left: 25px;
            }

            @media screen and (min-width: 992px) {
                font-size: 16px;
            }

            i {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                right: 3%;

                &.down {
                    background: url($footer-sprite + '#icon-arrow-down_blue') no-repeat;
                }

                &.up {
                    -webkit-transform: matrix(1, 0, 0, -1, 0, 0);
                    -moz-transform:    matrix(1, 0, 0, -1, 0, 0);
                    -o-transform:      matrix(1, 0, 0, -1, 0, 0);
                    transform:         matrix(1, 0, 0, -1, 0, 0);
                    background: url($footer-sprite + '#icon-arrow-down_blue') no-repeat;
                }
            }
        }

        .list {
            visibility: hidden;
            z-index: 1;
            position: absolute;
            top: 45px;
            width: 100%;
            padding: 20px 0 20px 0;
            background-color: #fff;
            border-bottom: 1px solid var(--color-primary);

            @media screen and (min-width: 767px) {
                padding: 30px;
            }

            ul {
                columns: 2;

                @media screen and (min-width: 768px) {
                    columns: 3;
                }

                li {
                    margin: 0 0 10px 0;
                    padding-left: 15px;
                    display: block;

                    -webkit-column-break-inside: avoid;
                    page-break-inside: avoid;
                    break-inside: avoid;

                    @media screen and (min-width: 767px) {
                        padding-left: 0;
                    }

                    a {
                        font-family: Poppins, sans-serif;
                        font-size: 16px;
                        color: var(--color-primary);
                        position: relative;
                        padding: 14px 14px 14px 0;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }

                        @media screen and (min-width: 767px) {
                            font-size: 14px;
                        }

                        @media screen and (min-width: 992px) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}