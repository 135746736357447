@import './variables.scss';

.currency {
    position: relative;

    .currency-btn {
        width: 90px;
        background: var(--color-neutral-lightest);
        border-radius: 20px;
        padding: 0px 30px 0 16px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border: 0;
        z-index: 2;

        span {
            font-size: 10px;
            font-family: Poppins-Regular, sans-serif;
            color: var(--color-primary);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        &::after {
            content: "";
            position: absolute;
            display: block;
            right: 10px;
            width: 10px;
            height: 10px;
            mask: url($header-common-icons-sprite + "#arrow-icon") center /
                cover no-repeat;
            background: var(--color-primary);
            transform: rotate(270deg);
            transition: transform 0.3s linear;
        }
    }

    .currency-content {
        position: absolute;
        bottom: 18px;
        width: 90px;
        font-size: 10px;
        z-index: -1;
    }

    .currency__list {
        margin-bottom: 0;
        border-radius: 20px 20px 0 0;
        list-style-type: none;
        background-color: var(--color-neutral-lightest);
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s linear, visibility 0.3s linear;
        padding-bottom: 16px;

        li {
            margin: 0 !important;
            color: var(--color-primary);

            label {
                display: flex;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                padding: 6px 25px 6px 12px;
                margin: 0;
                cursor: pointer;
    
                &:hover {
                    background: var(--color-supportive);
                }
            }

            input[type='radio']:checked + label {
                background: var(--color-supportive);
            }
        }

        li:not(:last-of-type) {
            margin-bottom: 10px;
        }

        input[type='radio'] {
            display: none;
        }
    }

    &.currency--opened {
        .currency-content {
            display: block;
            height: fit-content;
            z-index: 1;
        }
        
        .currency-btn {
            &::after {
                transform: rotate(90deg);
            }
        }

        .currency__list {
            opacity: 1;
            visibility: visible;
        }
    }
}

@media screen and (min-width: 1024px) {
    .currency {
        display: block;

        .currency-btn {
            height: 54px;
            width: 180px;
            padding: 0 50px 0 30px;
            border-radius: 30px;

            &::after {
                height: 16px;
                width: 16px;
                right: 20px;
            }

            &.default-pages, &.homepage {
                .currency-icon-btn {
                    width: 44px;
                    height: 44px;
                    margin: 0 auto 5px;

                    &.dollar::after,
                    &.euro::after,
                    &.pound::after {
                        width: 24px;
                        height: 24px;
                        margin: 8px 0 0 8px;
                    }

                    &.euro::after {
                        margin-left: 7px;
                    }
                }

                span {
                    display: block;
                }
            }

            span {
                font-size: 20px;
            }
        }

        .currency__list {
            font-size: 17px;
            border-radius: 30px 30px 0 0;
            padding-bottom: 24px;

            li {
                label {
                    padding: 10px 50px 10px 29px;
                }
            }
        }

        .currency-content {
            width: 180px;
            bottom: 30px;
        }
    }

    .currency-in-main-menu {
        display: none;
    }
}

.currency-in-main-menu {
    margin-left: 45px;

    .currency-in-main-menu-btn {
        position: relative;
        display: flex;
        width: 280px;
        border: 0;
        padding: 16px 10px 10px 0;
        color: var(--color-primary);
        background-color: var(--color-neutral-lightest);
        cursor: pointer;
        transition: 0.3s;
        font-family: Poppins, sans-serif;
        font-size: 23px;
        height: 40px;
        text-align: left;
        align-items: center;

        .currency-icon-btn {
            display: block;
            width: 15px;
            height: 20px;
            margin: 0 auto;
            line-height: 19px;
            color: var(--color-primary);
            margin: 2px 0 0 5px;
        } 
    }

    .currency-content {
        position: relative;
        display: none;
        font-size: 16px;

        & > strong {
            display: inline-block;
            width: 35px;
            font-weight: normal;
        }

        & > i {
            margin-left: 3px;
            font-size: 1.4rem;
            vertical-align: baseline;
            transition: transform .4s;
        }
    }

    .currency__list {
        position: absolute;
        top: -2px;
        padding: 10px;
        margin-bottom: 0;
        list-style-type: none;
        background-color: var(--color-neutral-lightest);
        transition: opacity .4s;
        border: 1px solid var(--color-primary);
        width: 170px;
        z-index: 1;
        border-top: none;
        border-radius: 5px;

        li:not(:last-of-type) {
            margin-bottom: 10px;
        }

        label {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 0;
            padding-left: 20px;
            color: var(--color-primary);
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 8px;
                left: 10px;
                width: 15px;
                height: 15px;
                border: 1px solid var(--color-primary);
            }

            & > span {
                margin-left: 10px;
            }

            & > span + span {
                width: 35px;
                text-align: center;
            }
        }

        input[type='radio'] {
            display: none;
        }

        input[type='radio']:checked + label::after {
            content: '';
            position: absolute;
            top: 5px;
            left: 13px;
            width: 13px;
            height: 12px;
            background: url($header-common-icons-sprite + '#icon-checked-blue') center / cover no-repeat;
        }
    }

    &.currency--opened {
        .currency-content {
            display: block;
        }
    }
}