.blue-bar {
    width: 100%;

    .container {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

    .green-box,
    .blue-box,
    .grey-box {
        width: 50%;
        padding: 15px 0;
        text-align: center;

        i {
            display: inline-block;
            width: 40px;
            height: 40px;
        }

        .text {
            max-width: 200px;
            margin: 0 auto;
            font-size: 14px;
            font-weight: normal;
        }
    }

    .green-box {
        background-color: #118c8f;

        .img-doodle-7 {
            background: url('/theme/frontend/_assets/images/doodles/doodle-personalized-white.png') no-repeat;
            background-size: contain;
        }

        .text {
            color: #ffffff;

            span {
                display: none;
            }
        }
    }

    .blue-box {
        background-color: var(--color-primary);

        .img-doodle-6 {
            background: url('/theme/frontend/_assets/images/doodles/doodle-handpicked-white.png') no-repeat;
            background-size: contain;
        }

        .text {
            color: #ffffff;
        }
    }

    .grey-box {
        width: 100%;
        background-color: #fab64e;

        .img-doodle-5 {
            background: url('/theme/frontend/_assets/images/doodles/doodle-variety-blue.png') no-repeat;
            background-size: contain;
        }

        .text {
            color: var(--color-primary);
        }
    }

    .tripadvisor-box {
        position: absolute;
        top: -50px;
        left: 0;
        right: 0;
        z-index: 1;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        border-radius: 50%;
        background-color: #ffffff;
        padding: 5px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media screen and (max-width: 992px) {
    .blue-bar {
        .container {
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .blue-bar {
        background-color: var(--color-primary);

        .green-box,
        .blue-box,
        .grey-box {
            width: 30%;
            background-color: transparent;

            .text {
                color: #ffffff;
            }
        }

        .green-box {
            .text {
                span {
                    display: initial;
                }
            }
        }

        .grey-box {
            .img-doodle-5 {
                background: url('/theme/frontend/_assets/images/doodles/doodle-variety-white.png') no-repeat;
                background-size: contain;
            }
        }

        .tripadvisor-box {
            width: 120px;
            height: 120px;
            left: auto;
            right: 15px;
            margin: 0;
        }
    }
}

@media screen and (min-width: 1200px) {
    .blue-bar {
        .green-box,
        .blue-box,
        .grey-box {
            display: flex;
            align-items: center;
            text-align: left;
            padding: 20px 0;

            i {
                margin: 0 5px 0 0;
            }

            .text {
                max-width: 250px;
                margin: 0;
                font-size: 16px;
            }
        }

        .green-box {
            .img-doodle-7 {
                width: 50px;
                height: 48px;
            }
        }

        .blue-box {
            .img-doodle-6 {
                width: 50px;
                height: 48px;
            }
        }

        .grey-box {
            .img-doodle-5 {
                width: 50px;
                height: 48px;
            }
        }

        .tripadvisor-box {
            top: -60px;
        }
    }
}
