.cookie-banner {
    position: fixed;
    bottom: 0;
    z-index: 1000000;
    display: flex;
    width: 100%;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(25px);
    box-shadow: 0 -5px 13px 0 rgba(0, 0, 0, 0.25);
    justify-content: center;
    align-items: center;
    height: 40%;

    .cookie-banner-body {
        display: flex;
        align-items: center;
        margin: 0;
        flex-direction: column;
    }

    .cookie-banner-text {
        margin: 0 8px 0 0;
        color: var(--color-primary);
        font-size: 22px;
        line-height: 28px;
        text-align: center;

        .cookie-baner-link {
            color: var(--color-primary);
        }
    }

    .cookie-banner-btn {
        margin-top: 20px;
        flex-shrink: 0;
        display: block;
        width: 95px;
        height: 40px;
        padding-top: 9px;
        font-size: 16px;
    }
}

@media screen and (min-width: 768px) {
    .cookie-banner {
        height: 90px;

        .cookie-banner-body {
            flex-direction: row;
            justify-content: center;
        }

        .cookie-banner-text {
            font-size: 20px;
            margin-right: 20px;
        }

        .cookie-banner-btn {
            margin-top: 0;
            width: 80px;
            padding-top: 7px;
            font-size: 20px;
        }
    }
}