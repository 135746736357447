@import "../common-variables.scss";
@import "./currency.scss";
@import "./ta-settings.scss";

.main-nav {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 10001;
    box-shadow: 0px 6px 7px 0 rgba(0, 0, 0, 0.25);
    background: var(--color-primary);

    a {
        color: var(--color-neutral-lightest);
        text-decoration: none;
        transition: .3s;

        &:hover {
            color: var(--color-accent-gold);
        }
    }

    .main-logo {
        display: block;
        width: 110px;
        height: 45px;
        background: var(--color-neutral-lightest);
        mask: url($header-common-icons-sprite + "#logo-blue") center / cover no-repeat;
    }

    .header-container {
        display: flex;
        align-items: center;
    }

    .main-nav-content {
        position: fixed;
        width: 100%;
        height: 100%;
        background: var(--color-primary);
        z-index: 100;
        transform: translateX(-100%);
        transition: 0.5s;

        &.open {
            transform: translateX(0);
        }

        &-header {
            display: flex;
            justify-content: space-between;
        }

        &-container {
            height: 100%;
            overflow-y: auto;
            padding-bottom: 100px;

            .menu-item {
                flex-direction: column;
            }

            .banner-container {
                margin-top: 40px;
                width: 225px;
                height: 225px;

                img {
                    margin-left: 35px;
                    width: 100%;
                    height: 100%;
                    max-width: 225px;
                    max-height: 225px;
                    object-fit: cover;
                }
            }
        }
    }

    .burger-btn-open {
        padding: 8px 0;
        border: none;
        background-color: transparent;
        margin-right: 0;

        .burger {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;

            .burger-icon {
                position: relative;
                display: block;
                width: 30px;
                height: 3px;
                margin: 0 auto;
                border-radius: 3px;
                background-color: var(--color-neutral-lightest);

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    left: 0;
                    display: block;
                    width: 30px;
                    height: 3px;
                    border-radius: 3px;
                    background-color: var(--color-neutral-lightest);
                }

                &::before {
                    top: 7px;
                }

                &::after {
                    bottom: 7px;
                }
            }
        }

        .burger-text {
            display: none;
        }
    }

    .main-nav-container {
        position: relative;
        width: 100%;
        height: var(--nav-bar-height);
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .search-input {
            width: 50px;

            &::placeholder {
                color: var(--color-neutral-lighter);
            }
        }

        .become-host-creator-container {
            position: relative;
            margin: 0 35px;
        }
    }

    .menu-burger {
        &.show {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
        }
    }

    .menu-burger {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-primary);
        transform: translateX(-100%);
        transition: 0.5s;
        z-index: 25;
        padding-left: 0;
        display: flex;
        flex-direction: column;

        &.show {
            transform: translateX(0);
        }
    }

    .close-icon {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        background: var(--color-neutral-lightest);
        mask: url($header-common-icons-sprite + "#close-icon") center / cover no-repeat;
    }

    .search-icon {
        background: var(--color-neutral-lightest);
        mask: url($header-common-icons-sprite + "#search-icon") center /
            cover no-repeat;
    }

    .functional-icon {
        display: block;
        width: 27px;
        height: 25px;
        cursor: pointer;
        transition: .3s;
    }

    .clear-input-icon {
        position: absolute;
        right: 10px;
        cursor: pointer;
        top: 30%;
        display: block;
        width: 15px;
        height: 15px;
        background: var(--color-neutral-lightest);
        mask: url($header-common-icons-sprite + "#close-icon") center / cover no-repeat;
    }

    .favorites-icon {
        background: var(--color-neutral-lightest);
        mask: url($header-common-icons-sprite + "#favourites-outline-icon") center /
            cover no-repeat;

        &:hover {
            mask: url($header-common-icons-sprite + "#favourites-icon") center /
            cover no-repeat;
        }
    }

    .suitcase-icon {
        background: var(--color-neutral-lightest);
        mask: url($header-common-icons-sprite + "#suitcase-outline-icon") center /
            cover no-repeat;

        &:hover {
            mask: url($header-common-icons-sprite + "#suitcase-icon") center /
                cover no-repeat;
        }
    }

    .user-icon {
        background: var(--color-neutral-lightest);
        mask: url($header-common-icons-sprite + "#icon-user-white") center /
            cover no-repeat;
        margin-right: 5px;
    }

    .arrow-icon {
        background: var(--color-neutral-lightest);
        display: block;
        width: 20px;
        height: 20px;
        mask: url($header-common-icons-sprite + "#arrow-icon") center /
            cover no-repeat;

        &.inverted {
            transform: rotate(180deg);
        }
    }

    .desktop-icon {
        display: none;
    }

    .icon-counter-container {
        position: relative;

        .icon-counter {
            position: absolute;
            top: -2px;
            left: 12px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            font-size: 10px;
            font-family: Poppins-SemiBold, sans-serif;
            font-style: normal;
            line-height: 22px;
            color: var(--color-neutral-lightest);
            background-color: var(--red-basic);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .menu-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px 0 15px;
        margin-bottom: 20px;

        .burger-btn-close {
            padding: 8px 0;
            border: 0;
            background-color: transparent;
        }
    }

    .menu-container {
        margin-left: 35px;
    }

    .menu-list {
        margin-left: 20px;
        margin-right: 90px;

        .menu-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            cursor: pointer;

            .menu-item-title {
                font-family: Poppins-Medium, sans-serif;
                font-size: 20px;
                border-bottom: 2px solid transparent;
            }

            &.active {
                .menu-item-title {
                    border-bottom-color: var(--color-accent-gold);
                }
            }
        }
    }

    .secondary-menu-item-title {
        margin-bottom: 15px;
        width: fit-content;
        font-family: Poppins-Medium, sans-serif;
        font-size: 20px;

        &.active {
            border-bottom: 2px solid var(--color-accent-gold);
        }
    }

    .mobile-title {
        margin-bottom: 15px;
    }

    .secondary-menu-items {
        margin-bottom: 40px;

        .secondary-menu-item {
            margin-bottom: 10px;
            font-family: Poppins-Medium, sans-serif;
            font-size: 16px;
            transition: .3s;

            &:hover {
                border-bottom-color: var(--color-accent-gold);
            }
        }
    }

    .search-wrapper {
        position: relative;
        display: flex;

        .search-input {
            width: 100%;
            height: 40px;
            padding: 0 0 0 50px;
            background: var(--color-neutral-lightest);
            color: var(--color-primary);
            border: none;
            display: none;
        }

        &.full-size {
            width: 100%;

            .search-icon {
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);
            }

            .search-input {
                display: inline-block;
            }
        }
    }

    .menu-list-desktop {
        display: none;
    }

    .menu-list-mobile {
        padding-bottom: 40px;
        margin-bottom: 20px;
        border-bottom: 2px solid var(--color-primary);
    }

    .mobile-icons {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-left: 20px;
    }

    .mobile-icon {
        display: flex;
        margin-top: auto;

        .icon-title {
            margin-left: 10px;
            font-size: 20px;
            font-family: Poppins-Medium, sans-serif;
            color: var(--color-neutral-lightest);
        }
    }

    .search-block {
        position: fixed;
        width: 100%;
        height: 100%;
        padding-bottom: 70px;
        background: var(--color-primary);
        display: flex;
        align-items: flex-start;
        z-index: 100;
        transform: translateX(105%);
        transition: .3s;
        overflow-y: auto;
        overflow-x: hidden;

        &.open {
            transform: none;
        }

        .main-logo {
            display: none;
        }

        .search-container {
            width: 100%;
            margin: 30px auto 0 auto;

            .search-icon {
                background: var(--color-primary);
            }
        }

        .main-nav-container {
            height: auto;
        }

        .main-block {
            width: 100%;
            display: flex;
        }

        .main-nav-container {
            flex-direction: column;
        }

        .search-wrapper {
            margin-bottom: 30px;

            .cancel-search-btn {
                right: -5px;
                bottom: -30px;
                display: flex;
                align-items: center;
                border: none;
                background: transparent;
                color: var(--color-neutral-lightest);

                .close-icon {
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                }
            }
        }

        .products {
            display: flex;
            overflow-x: auto;
            width: 110%;
            padding: 10px 20px 20px 0;
            box-sizing: border-box;
            gap: 10px;


            .product-card {
                position: relative;
                min-width: 260px;
                min-height: 260px;
                max-width: 260px;
                max-height: 260px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
                cursor: pointer;
                transition: .3s;

                .product-link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                .img-content {
                    height: 80%;
                    margin-bottom: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                .product-title {
                    padding: 0 5px 10px 5px;
                    color: var(--color-neutral-lightest);

                    &-title {
                        font-size: clamp(14px, 1.1vw, 18px);
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        margin: 0;
                    }
                }

                &:hover {
                    transform: scale(1.01);

                    .img-content {
                        filter: brightness(80%);

                        img {
                            height: 96%;
                        }
                    }

                    .product-title {
                        color: var(--color-accent-gold);
                    }
                }
            }
        }
    }

    .search-results {
        width: 100%;

        &-title {
            font-family: Poppins-Medium, sans-serif;
            font-size: 14px;
            margin-bottom: 25px;
            color: var(--color-neutral-lightest);
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: 20px;

            li {
                font-family: Poppins-Medium, sans-serif;
                font-size: 16px;
            }
        }
    }

    .underlined {
        border-bottom: 2px solid var(--color-primary);
        width: fit-content;
    }

    .error {
        color: var(--red-basic);
        text-align: center;
    }
}

@media screen and (min-width: 992px) {
    .main-nav {
        .main-nav-content {
            position: relative;
            display: none;
            overflow: hidden;
            transform: none;
            padding-top: 35px;

            &.open {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &-header {
                display: none;
            }

            &-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 0 20px 70px 20px;

                .banner-container {
                    width: 100%;
                    height: 155px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 100%;
                        height: 100%;
                        max-width: 992px;
                        max-height: 155px;
                        object-fit: cover;
                        margin: 0;
                    }
                }
            }
        }

        .burger-btn-open {
            display: none;
        }

        .main-logo {
            width: 135px;
            height: 75px;
        }

        .menu-burger {
            position: relative;
            transform: none;
            height: auto;
            width: auto;
        }

        .menu-header {
            display: none;
        }

        .arrow-icon {
            display: none;
        }

        .menu-title {
            display: none;
        }

        .menu-content-container {
            display: flex;
            flex-direction: column;

            .menu-container {
                margin: 0;
                height: 100%;
                display: flex;
                justify-content: center;
                gap: 38px;
            }
        }

        .desktop-icons {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        .desktop-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;

            .icon-title {
                display: none;
            }
        }

        .mobile-icon {
            display: none;
        }

        .user-icon {
            background: var(--color-neutral-lightest);
            mask: url($header-common-icons-sprite + "#user-outline-icon") center /
                cover no-repeat;
            margin: 0;

            &:hover {
                mask: url($header-common-icons-sprite + "#user-icon") center /
                cover no-repeat;
            }
        }

        .mobile-icons {
            display: none;
        }

        .secondary-menu {
            max-width: 290px;

            &.mobile-title {
                flex: none;
            }
        }

        .menu-list {
            display: flex;
            margin: 0;

            .menu-item {
                margin-bottom: 0;

                &:not(:last-child) {
                    margin-right: 55px;
                }

                .menu-item-title {
                    font-size: 16px;
                }
            }
        }

        .secondary-menu-item-title {
            font-size: 14px;
            font-family: Poppins-SemiBold, sans-serif;
            margin-bottom: 25px;
        }

        .secondary-menu-items {
            margin: 0;

            .secondary-menu-item {
                margin-bottom: 15px;
                max-width: 250px;
                font-family: Poppins-Medium, sans-serif;
                font-size: 12px;
            }
        }

        .mobile-title {
            display: none;
        }

        .menu-list-desktop {
            display: flex;
        }

        .menu-list-mobile {
            display: none;
        }

        .search-results {
            &-title {
                font-size: 20px;
                color: var(--color-neutral-lightest);
            }
        }

        .search-block {
            height: auto;
            box-shadow: -11px 6px 7px 0 rgba(0, 0, 0, .25);

            .products {
                width: 100%;
                margin-top: 90px;
                margin-left: 80px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .product-card {
                    min-width: 150px;
                    min-height: 150px;
                    max-width: 150px;
                    max-height: 150px;

                    .img-content {
                        height: 75%;
                    }

                    .product-title {

                        &-title {
                            font-size: clamp(12px, 1.1vw, 18px);
                        }
                    }
                }
            }

            .main-nav-container {
                height: auto;
            }

            .main-logo {
                display: block;
                align-self: flex-start;
                margin-top: calc((var(--nav-bar-height) / 2 - 38px));
            }

            .search-container {
                width: auto;
            }

            .search-wrapper {
                display: block;
                width: 500px;
                margin-bottom: 30px;

                .cancel-search-btn {
                    position: absolute;
                    right: -100px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .suggestions-block {
                display: flex;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .main-nav {
        .search-block {

            .products {
                .product-card {
                    min-width: 170px;
                    min-height: 170px;
                    max-width: 170px;
                    max-height: 170px;
                }
            }

            .search-wrapper {
                .search-icon {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .search-input {
                    display: inline-block;
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .main-nav {

        .search-block {

            .products {

                .product-card {
                    min-width: 240px;
                    min-height: 240px;
                    max-width: 240px;
                    max-height: 240px;

                    .product-title {

                        &-title {
                            font-size: clamp(14px, 1.1vw, 18px);
                        }
                    }
                }
            }
        }
    }
}

// @media screen and (min-width: 1920px) {
//     .main-nav {
//         .main-nav-container {
//             max-width: 1905px;
//         }
//     }
// }