.ta-settings {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -1px -5px 11px 0 rgba(0, 0, 0, 0.15);
    background: var(--color-neutral-lightest);

    .login-btn, .ta-logout-btn {
        color: var(--color-primary);
    }

    .ta-settings-btn {
        border: 0;
        text-align: center;
        background-color: var(--color-primary);
        width: 130px;
        border-radius: 35px;
        height: 40px;
        padding: 5px;
        display: flex;
        align-items: center;
        color: var(--color-neutral-lightest);
        margin-right: 30px;

        img {
            min-width: 25px;
            min-height: 25px;
            max-height: 25px;
            max-width: 25px;
            width: 25px;
            height: 25px;
            border: 2px solid var(--color-primary);
            border-radius: 50%;
            background: var(--color-neutral-lightest);
        }

        .ta-settings-name {
            font-size: 14px;
            line-height: 18px;
            font-weight: normal;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 75px;
            overflow: hidden;
            color: var(--color-neutral-lightest);
            font-family: Poppins-Medium, sans-serif;
        }
    }

    .ta-settings-content {
        display: none;
        position: absolute;
        bottom: 75px;
        right: 1px;
        width: 100%;
        height: fit-content;
        padding: 10px;
        border-radius: 3px;
        text-align: center;
        background-color: var(--color-neutral-lightest);
        box-shadow: 0 0 5px 0 var(--color-neutral-lighter);
        z-index: 1000;

        &.show {
            display: block;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                list-style: none;
                margin-bottom: 10px;

                .ta-logout-btn {
                    border: 0;
                    background-color: transparent;
                    color: var(--color-primary);

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .ta-settings {
        position: relative;
        box-shadow: none;
        background: none;

        .ta-settings-btn {
            background: transparent;
            margin: 0;

            .ta-settings-name {
                display: none;
            }
        }

        .ta-settings-content {
            width: 180px;
            bottom: auto;
            top: 35px;
            right: -35px;
        }
    }
}
