.sign-up-form-klavio {
    position: relative;
    background: linear-gradient(0deg, rgba(253,247,236,0) 20%, rgba(253,247,236,1) 95%);

    &.container-xl {
        padding: 40px 40px 50px;
    }

    .sing-up-img {
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .sign-up-form-wrapper {
        &.container-l {
            padding: 0;
        }
        .title {
            margin-bottom: 13px;
            font-size: 25px;
            max-width: 300px;
            color: var(--color-text);
        }

        .sign-up-form-body {
            display: flex;
            flex-direction: column;
            position: relative;

            .klaviyo-form {
                padding: 0 !important;

                form {
                    >div {
                        >div {
                            display: block !important;

                            >div {
                                padding: 0 !important;
                                margin-bottom: 6px;
                                width: 180px;
                            }
                        }
                    }
                }

                input {
                    border-radius: 20px !important;
                    border-color: var(--color-neutral-lightest) !important;
                    background-color: var(--color-neutral-lightest) !important;
                    color: var(--color-primary) !important;
                    height: 34px !important;
                    padding: 0 16px !important;

                    &::placeholder {
                        font-size: 15px !important;
                        color: var(--color-primary) !important;
                    }

                    &:hover {
                        border-color: var(--color-primary) !important;
                    }
                }

                button {
                    width: 90px !important;
                    border-radius: 20px !important;
                    text-transform: lowercase !important;
                    font: 400 15px 'Poppins-Regular', sans-serif !important;
                    padding: 8px 3px !important;
                    letter-spacing: 0.8px !important;
                    color: var(--color-primary) !important;
                    background-color: var(--color-accent-gold) !important;

                    &:hover {
                        color: var(--color-accent-gold) !important;
                        background-color: var(--color-primary) !important;
                    }
                }

                .ql-font-poppins {
                    font-size: 29px !important;
                    color: var(--color-text) !important;
                    padding: 40px 0 !important;
                    display: block !important;
                    text-align: left;
                }
            }
        }

        .form-group-checkbox {
            padding: 10px 13px;
            background-color: rgba(253, 247, 236, 0.6);
            border-radius: 20px;
            max-width: 350px;

            label {
                font-weight: 400;
                position: relative;
                padding-left: 32px;
                font-size: 10px;
                cursor: pointer;
                display: inline-block;

                a {
                    text-decoration: underline;
                    transition: color 0.3s linear, text-decoration 0.3s linear;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            input[type='checkbox'] {
                display: none;
            }

            label::before {
                content: '';
                position: absolute;
                top: 2px;
                left: 0;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                border: 1px solid var(--color-neutral-lightest);
                background: var(--color-neutral-lightest);
            }

            label::after {
                position: absolute;
                top: 0;
                left: 0px;
                display: none;
                width: 21px;
                height: 20px;
                content: '';
                background-color: var(--color-primary);
                mask: url($footer-sprite + '#icon-check') no-repeat center;
                mask-size: contain;
            }

            input[type='checkbox']:checked + label::after {
                display: block;
            }
        }
    }

    .overlay {
        display: block;
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 100px;
        height: 40px;
        background: rgba(255, 255, 255, 0);
        z-index: 1;
        cursor: not-allowed;

        &.hide-overlay {
            width: 0;
            height: 0;
            display: none;
        }
    }

    @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
        100% { transform: translateX(0); }
    }

    @-webkit-keyframes shake {
        0% { -webkit-transform: translateX(0); }
        25% { -webkit-transform: translateX(-5px); }
        50% { -webkit-transform: translateX(5px); }
        75% { -webkit-transform: translateX(-5px); }
        100% { -webkit-transform: translateX(0); }
    }

    .shake-label {
        animation: shake 0.6s ease-in-out;
        -webkit-animation: shake 0.6s ease-in-out;
    }
}