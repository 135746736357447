@import './normalize.scss';
@import './btn-back-to-top.scss';
@import './header/main-popup';
@import './common-variables.scss';

:root {
    --red-basic: #e84042;
    --blue-basic: #0C4A60;
    --green-basic: #118c8f;
    --yellow-basic: #e3aa2c;
    --white: #fdf7ec;
    --white-basic: #fff;
    --grey-dark: #c8bea0;
    --grey-basic: #cccccc;
    --grey-light-basic: #f5f4f2;
    --blue-grey-basic: #6a7098;
    --pink-basic: #eb22b5;

    --color-primary: #0C4A60;
    --color-primary-light: #537678;
    --color-supportive: #c8bea0;
    --color-accent-gold: #e3aa2c;
    --color-accent-rose: #d97c5b;
    --color-neutral-lightest: #fdf7ec;
    --color-neutral-lighter: #f5f4f2;
    --color-neutral-light: #ecebe8;
    --color-text: #063538;


    --nav-bar-height: 75px;
    --experience-booking-form-width: 355px;

    @media screen and (min-width: 1440px) {
        --experience-booking-form-width: 430px;
    }

    @media screen and (min-width: 992px) {
        --nav-bar-height: 90px;
    }
}

* {
    box-sizing: border-box;
    outline: none !important;
}

// ===== reset =====
ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

[hidden],
.hidden,
.no-display {
    display: none !important;
}

.hidden-block {
    visibility: hidden;
    height: 0;
    transition: height 0.3s linear;
    
    &.active {
        visibility: visible;
        height: fit-content;
    }
}

#gtx-trans {
  display: none;
}

.hide {
	visibility: hidden;
	opacity: 0;
}

html {
    scroll-padding-top: 120px;
    scroll-behavior: smooth;
}

body {
    margin: 0;
    color: var(--color-primary);
    background: var(--color-neutral-lightest);
    font-family: Poppins, sans-serif;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.5px;
    -webkit-font-smoothing: antialiased;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
}

button {
    font-family: Poppins-Medium, sans-serif;
    cursor: pointer;
    transition: 0.3s;
    &.white {
        border: 2px solid var(--color-primary);
        color: var(--color-primary);
        background-color: var(--color-neutral-lightest);

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lightest);
            background-color: var(--color-primary);
            border-color: var(--color-neutral-lightest);
        }
    }

    &.transparent {
        border: 2px solid var(--color-primary);
        color: var(--color-primary);
        background-color: transparent;

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lightest);
            background-color: var(--color-primary);
            border-color: var(--color-neutral-lightest);
        }
    }

    &.yellow {
        background: var(--color-accent-gold);
        color: var(--color-primary);
        border: none;

        &:hover,
        &:focus,
        &:active {
            background-color: var(--color-primary);
            color: var(--color-neutral-lightest);
        }
    }

    &.grey {
        border: 2px solid var(--color-neutral-lighter);
        color: var(--color-primary);
        background-color: rgba(250, 250, 250);

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lighter);
            background-color: var(--color-neutral-lightest);
            border-color: var(--color-neutral-lighter);
        }
    }

    &.grey-blue {
        border: 2px solid var(--color-primary);
        color: var(--color-primary);
        background-color: transparent;

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lightest);
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }

        @media only screen and (min-width: 768px) {
            color: var(--color-neutral-lightest);
            border-color: var(--color-neutral-lightest);

            &:focus,
            &:hover,
            &:active {
                color: var(--color-primary);
                background-color: var(--color-neutral-lightest);
            }
        }
    }

    &.blue {
        border: 2px solid var(--color-neutral-lightest);
        color: var(--color-neutral-lightest);
        background-color: var(--color-primary);

        &:focus,
        &:hover,
        &:active {
            color: var(--color-primary);
            background-color: var(--color-neutral-lightest);
            border-color: var(--color-primary);
        }
    }
}

a {
    color: var(--color-primary);
    text-decoration: none;
    transition: color 0.3s linear;

    &:hover {
        color: var(--color-accent-gold);
    }
}

img {
    max-width: 100%;
}

.substrate {
	position: fixed;
	top: 0;
	z-index: 200;
	display: none;
	width: 100vw;
	height: 100vh;
	background-color: #00000050;

	&.show {
		display: block;
	}
}

.invisible {
    position: absolute;
    top: -9999px;
    left: -9999px;
}

.SysFalso-font {
    font-family: SysFalso-Italic, sans-serif;
}

// ===== custom scroll for Mozilla Firefox =====
html,body {
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary) var(--color-neutral-lighter);
}

// ===== headings styles =====
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h7, .h8 {
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
    line-height: 1.2;
    margin: 0 0 15px;
    @media screen and (min-width: 768px) {
        margin: 0 0 30px;
    }
}

h1, .h1 {
    font-size: 38px;
    @media screen and (min-width: 992px) {
        font-size: 44px;
    }
}

h2, .h2 {
    font-size: 20px;
    @media screen and (min-width: 768px) {
        font-size: 29px;
    }
}

h3, .h3 {
    font-size: 23px;
    @media screen and (min-width: 768px) {
        font-size: 25px;
    }
}

h4, .h4 {
    font-size: 20px;
    @media screen and (min-width: 768px) {
        font-size: 22px;
    }
}

h5, .h5 {
    font-size: 17px;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
}

h6, .h6 {
    font-size: 12px;
    @media screen and (min-width: 768px) {
        font-size: 15px;
    }
}

.h7 {
    font-size: 9px;
    @media screen and (min-width: 768px) {
        font-size: 12px;
    }
}

.h8 {
    font-size: 11px;
}

// ===== custom scroll =====
::-webkit-scrollbar {
    width: 5px;

    @media screen and (min-width: 992px){
        width: 8px !important;
    }
}

::-webkit-scrollbar-track {
    background: var(--color-neutral-lighter);
}

::-webkit-scrollbar-thumb {
    background: var(--color-primary);
}

::-webkit-scrollbar-thumb:hover {
    background: var(--color-primary);
}

// ===== class for center sections =====
.container {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;

    @media screen and (min-width: 768px) {
        width: 720px;
    }

    @media screen and (min-width: 992px) {
        width: 960px;
    }

    @media screen and (min-width: 1200px) {
        width: 1140px;
    }

    @media screen and (min-width: 1442px) {
        width: 1400px;
    }
}

.container-xl {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1920px;

    &.remove-padding {
        padding: 0;

        @media screen and (min-width: 1600px) {
            padding: 0 0 0 126px;
        }
    }
}

.container-l {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1440px;
}

.container-m {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px !important;
}

.container-s {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px !important;
    max-width: 960px !important;
}

// ===== custom button for 'tag a' =====
.a-btn {
    display: inline-block;
    width: auto;
    border: 2px solid var(--color-neutral-lightest);
    padding: 10px 0;
    color: var(--color-neutral-lightest);
    background-color: var(--color-primary);
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
    font-family: Poppins-Medium, sans-serif;

    &:focus,
    &:hover,
    &:active {
        color: var(--color-primary);
        background-color: var(--color-neutral-lightest);
        border-color: var(--color-primary);
    }

    &.white {
        border: 2px solid var(--color-primary);
        color: var(--color-primary);
        background-color: var(--color-neutral-lightest);

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lightest);
            background-color: var(--color-primary);
            border-color: var(--color-neutral-lightest);
        }
    }

    &.transparent {
        border: 2px solid var(--color-primary);
        color: var(--color-primary);
        background-color: transparent;

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lightest);
            background-color: var(--color-primary);
            border-color: var(--color-neutral-lightest);
        }
    }

    &.yellow {
        background: var(--color-accent-gold);
        color: var(--color-primary);
        border: none;

        &:hover,
        &:focus,
        &:active {
            background-color: var(--color-primary);
            color: var(--color-neutral-lightest);
        }
    }

    &.grey {
        border: 2px solid var(--color-neutral-lighter);
        color: var(--color-primary);
        background-color: rgba(250, 250, 250);

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lighter);
            background-color: var(--color-neutral-lightest);
            border-color: var(--color-neutral-lighter);
        }
    }

    &.grey-blue {
        border: 2px solid var(--color-primary);
        color: var(--color-primary);
        background-color: transparent;

        &:focus,
        &:hover,
        &:active {
            color: var(--color-neutral-lightest);
            background-color: var(--color-primary);
            border-color: var(--color-primary);
        }

        @media only screen and (min-width: 768px) {
            color: var(--color-neutral-lightest);
            border-color: var(--color-neutral-lightest);

            &:focus,
            &:hover,
            &:active {
                color: var(--color-primary);
                background-color: var(--color-neutral-lightest);
            }
        }
    }

    &.blue {
        border: 2px solid var(--color-neutral-lightest);
        color: var(--color-neutral-lightest);
        background-color: var(--color-primary);

        &:focus,
        &:hover,
        &:active {
            color: var(--color-primary);
            background-color: var(--color-neutral-lightest);
        }
    }
}

    
.btn-more {
    color: var(--color-accent-gold);
    display: flex;
    align-items: center;
    font: 500 15px 'Poppins-Medium', sans-serif;

    @media screen and (min-width: 768px) {
        font-size: 20px;
    }

    &:hover {
        color: var(--color-primary);
        
        &::after {
            background-color: var(--color-primary);
        }
    }

    &::after {
        display: block;
        content: '';
        width: 12px;
        height: 8px;
        background-color: var(--color-accent-gold);
        mask: url($main-icons-sprite + '#icon-arrow-down') no-repeat center;
        mask-size: cover;
        transform: rotate(-90deg) translate(-5%, 2px);
        transition: background-color 0.3s linear, transform 0.3s linear;
        margin-left: 4px;

        @media screen and (min-width: 768px) {
            width: 17px;
            height: 10px;
        }
    }

    &.active {
        &::after {
            transform: rotate(0) translate(-5%, 2px);
        }
    }
}

.btn-outline {
    display: block;
    width: fit-content;
    min-width: 300px;
    border: 1px solid var(--color-primary);
    border-radius: 20px;
    padding: 10px 23px;
    color: var(--color-primary);
    background-color: transparent;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s linear, color 0.3s linear;
    font: 500 15px 'Poppins-Medium', sans-serif;
    text-align: center;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        font-size: 18px;
    }

    &:hover {
        background-color: var(--color-primary);
        color: var(--color-neutral-lightest);
    }
}

.btn-primary {
    display: block;
    width: fit-content;
    border: none;
    border-radius: 20px;
    padding: 9px 30px;
    min-width: 170px;
    color: var(--color-primary);
    background-color: var(--color-accent-gold);
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s linear, color 0.3s linear;
    font: 500 15px 'Poppins', sans-serif;
    text-align: center;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        font-size: 25px;
        padding: 14px 37px;
        border-radius: 40px;
        min-width: 250px;
    }

    &:hover {
        background-color: var(--color-primary);
        color: var(--color-accent-gold);
    }
}

.btn-link {
    display: block;
    width: fit-content;
    border: none;
    padding: 9px 30px;
    min-width: 170px;
    color: var(--color-neutral-lightest);
    background-color: transparent;
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
    transition: color 0.3s linear;
    font: 500 15px 'Poppins', sans-serif;
    text-align: center;
    line-height: 1.2;

    @media screen and (min-width: 768px) {
        font-size: 25px;
        padding: 14px 37px;
        min-width: 250px;
    }

    &:hover {
        color: var(--color-accent-gold);
    }
}

// ===== for slick-slider =====
.slick-slider {
    display: block;
}

.slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translate(0, -50%);
    width: 60px;
    height: 50px;
    border-radius: 50%;
    border: 0;
    background-color: transparent;
    font-size: 0;
}

.slick-next {
    right: -22px;

    &::after {
        content: '';
        display: block;
        width: 55px;
        height: 40px;
        margin: 0 -2px 0 0;
        background: var(--color-accent-gold);
        mask: url($main-icons-sprite + '#carousel-arrow') no-repeat;
        mask-size: contain;
    }
}

.slick-prev {
    left: -100px;

    &::after {
        content: '';
        display: block;
        width: 55px;
        height: 40px;
        margin: 0 0 0 -2px;
        -webkit-transform: matrix(-1, 0, 0, 1, 0, 0);
        -moz-transform: matrix(-1, 0, 0, 1, 0, 0);
        -o-transform: matrix(-1, 0, 0, 1, 0, 0);
        transform: matrix(-1, 0, 0, 1, 0, 0);
        background: var(--color-accent-gold);
        mask: url($main-icons-sprite + '#carousel-arrow') no-repeat;
        mask-size: contain;
    }
}

.slick-disabled {
    opacity: 0.5;
}

.no-scroll {
    overflow: hidden;

    @media screen and (max-width: 767px) {
        position: fixed;
    }
}

// ===== base structure for section box
.box-section {
    width: 100%;
    padding: 70px 0;

    &.bc-white-grey {
        background-color: var(--color-neutral-lightest);

        @media only screen and (min-width: 768px) {
            background-color: var(--color-neutral-light);
        }
    }

    &.bc-grey-blue {
        background-color: var(--color-neutral-light);

        @media only screen and (min-width: 768px) {
            background-color: var(--color-primary);
        }
    }

    &.bc-blue-grey {
        background-color: var(--color-primary);
        @media only screen and (min-width: 768px) {
            background-color: var(--color-neutral-light);
        }
    }

    &.bc-white-blue {
        background-color: var(--color-neutral-lightest);

        @media only screen and (min-width: 768px) {
            background-color: var(--color-primary);
        }
    }

    &.bc-grey-white {
        background-color: var(--color-neutral-light);

        @media only screen and (min-width: 768px) {
            background-color: var(--color-neutral-lightest);
        }
    }

    &.white {
        background-color: var(--color-neutral-lightest);
    }

    &.grey {
        background-color: var(--color-neutral-light);
    }

    &.blue {
        background-color: var(--color-primary);
    }

    .container {
        & > .title {
            margin: 0 0 25px 0;
            padding: 0 15px;
            font-size: 22px;
            text-align: center;

            .pc-ver {
                display: none;
            }

            @media screen and (min-width: 768px) {
                padding: 0;
                text-align: initial;

                .pc-ver {
                    display: initial;
                }
            }

            @media screen and (min-width: 992px) {
                font-size: 26px;
            }

            @media screen and (min-width: 1200px) {
                font-size: 30px;
            }
        }

        & > .city-page__best-things {
            padding: 0 15px;
        }

        & > .text {
            margin: 0 0 30px 0;
            padding: 0 15px;
            font-size: 16px;
            text-align: justify;

            @media screen and (min-width: 768px) {
                padding: 0;
                text-align: initial;
            }

            @media screen and (min-width: 992px) {
                font-size: 20px;
            }

            @media screen and (min-width: 1200px) {
                font-size: 24px;
            }
        }

        .a-btn {
            font-weight: bold;
            border-width: 2px;
        }
    }
}

.popup-message {
    .alert {
        text-align: center;
        margin-bottom: 5px;

        &.alert-danger {
            color: var(--red-basic);
        }

        &.alert-warning {
            color: var(--color-accent-gold);
        }

        &.alert-info {
            color: var(--green-basic);
        }
    }
}

.flex-column-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main-header {
    margin-top: var(--nav-bar-height);  
}

/* ------------------------------------------------------------ *\
	Classes for the colors;
\* ------------------------------------------------------------ */
.basic-red {
	color: var(--red-basic);
}

.basic-blue {
	color: var(--color-primary);
}

.basic-green {
	color: var(--green-basic);
}

.basic-yellow {
	color: var(--color-accent-gold);
}

.basic-white {
    color: var(--color-neutral-lightest);
}

.desktop-title {
    display: none;

    @media screen and (min-width: 768px) {
        display: initial;
    }
}

.bc-red {
	background-color: var(--red-basic);
}

.bc-blue {
	background-color: var(--color-primary);
}

.bc-green {
	background-color: var(--green-basic);
}

.bc-yellow {
	background-color: var(--color-accent-gold);
}

.bc-grey-light-basic {
    background-color: var(--color-neutral-light);
}