.footer-info {
    color: var(--color-neutral-lightest);
    background-color: var(--color-primary);
    font-size: 10px;
    line-height: normal;
    padding: 50px 0 30px;

    .contacts-box {
        &.container-l {
            padding: 0 45px;
        }
    }

    .company-contacts {
        margin-bottom: 22px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px 50px;

        .page-links {
            display: flex;
            gap: 50px;
        }

        .footer-info-list {
            text-transform: capitalize;

            li {
                margin: 0 0 8px 0;
            }

            a {
                position: relative;
                color: var(--color-neutral-lightest);
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .partnership {
            .footer-info-list {
                margin-bottom: 5px;
            }
        }

        .company-contacts-list {
            i {
                display: block;
                width: 16px;
                height: 16px;
                background-color: var(--color-neutral-lightest);
                mask-size: contain !important;

                &.icon-email {
                    mask: url($footer-sprite + '#email') no-repeat;
                }

                &.icon-phone {
                    mask: url($footer-sprite + '#phone') no-repeat;
                }

                &.icon-whatsapp {
                    mask: url($footer-sprite + '#whatsapp') no-repeat;
                }

                &.icon-live-chat {
                    mask: url($footer-sprite + '#cellphone') no-repeat;
                }
            }

            li {
                margin-bottom: 8px;
            }
        }

        .contact-link {
            color: var(--color-neutral-lightest);
            text-decoration: none;
            word-break: break-word;
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: fit-content;

            &:hover {
                text-decoration: underline;
            }
        }

    }

    .title {
        color: var(--color-neutral-lightest);
        font-size: 15px;
        margin-bottom: 8px;
    }

    .footer-social-network {
        .social-links {
            display: flex;
            justify-content: flex-start;
            gap: 18px;

            a {
                display: block;
                width: 27px;
                height: 27px;
                transition: .3s;
                background: var(--color-accent-gold);
                mask-size: contain !important;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        .facebook {
            mask: url($socials-sprite + '#facebook_yellow') no-repeat;
        }

        .twitter {
            mask: url($socials-sprite + '#twitter_yellow') no-repeat;
        }

        .instagram {
            mask: url($socials-sprite + '#instagram_yellow') no-repeat;
        }

        .linkedin {
            mask: url($socials-sprite + '#linkedin_yellow') no-repeat;
        }

        .pinterest {
            mask: url($socials-sprite + '#pinterest_yellow') no-repeat;
        }

        .youtube {
            mask: url($socials-sprite + '#youtube-logotype_yellow') no-repeat;
        }

        .tiktok {
            mask: url($socials-sprite + '#tiktok_yellow') no-repeat;
        }

        .terms-privacy-faqs-sitemap-links {
            padding-top: 13px;
            font-size: 10px;

            ul {
                display: flex;
                gap: 30px;

                a {
                    color : var(--color-neutral-lightest);
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}