.footer-info {
    .company-contacts {
        gap: 100px;
    }
    
    .footer-social-network {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 50px;

        .logo-social-links {
            order: 2;
        }

        .social-links {
            justify-content: flex-end;
        }

        .terms-privacy-faqs-sitemap-links {
            padding-top: 0;

            ul {
                justify-content: space-between;
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .footer-info {
        font-size: 17px;
        padding-bottom: 50px;

        .contacts-box {
            &.container-l {
                padding: 0 15px;
            }

            .company-contacts {
                justify-content: space-between;
                margin-bottom: 46px;

                li {
                    margin-bottom: 20px;
                }
            }

            .footer-info-list {
                li {
                    margin-bottom: 20px;
                }
            }
        }
        
        .page-links {
            gap: 100px;
            width: 44%;
            justify-content: space-between;
        }

        .contacts-area {
            width: 40%;
        }
        
        .title {
            font-size: 25px;
            margin-bottom: 25px;
        }

        .footer-social-network {
            .social-links {
                gap: 30px;

                a {
                    width: 60px;
                    height: 60px;
                }
            }

            .terms-privacy-faqs-sitemap-links {
                font-size: 17px;
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .footer-info {
        .footer-social-network {
            gap: 100px;

            .social-links {
                gap: 40px;
            }

            .terms-privacy-faqs-sitemap-links {
                ul {
                    gap: 40px;
                }
            }
        }
    }
}