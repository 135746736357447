@import './sign-up-form-mobile.scss';
@import './footer-info-block-mobile.scss';
@import './location-list.scss';
@import './cookie-baner.scss';
@import './footer-image.scss';

@media screen and (min-width: 768px) {
  @import './footer-info-block-desktop.scss';
  @import './sign-up-form-desktop.scss';
}

.main-footer {
    .box-section {
        background: var(--color-neutral-lightest);
    }
}