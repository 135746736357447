.sign-up-form-klavio {
    &.container-xl {
        padding: 70px 15px;
    }

    .sign-up-form-wrapper {
        position: relative;

        .form-group-checkbox {
            padding: 12px 28px 12px 34px;
            max-width: 575px;
        }

        .title {
            max-width: 586px;
            font-size: 29px;
            margin-bottom: 40px;
        }

        .sign-up-form-body {
            .klaviyo-form {
                form {
                    >div {
                        >div {
                            display: flex !important;

                            >div {
                                margin: 0 40px 26px 0;
                            }
                        }
                    }  
                }
            }
        }
    }

    .overlay {
        bottom: 20px;
        height: 46px;
    }
}

@media screen and (min-width: 1024px) {
    .sign-up-form-klavio {
        &.container-xl {
            padding-top: 100px;
            padding-bottom: 100px;
        }

        .sign-up-form-wrapper {
            .sign-up-form-body {
                max-width: 1155px;

                .klaviyo-form {
                    input {
                        height: 60px !important;
                        border-radius: 60px !important;
                        padding: 0 40px !important;

                        &::placeholder {
                            font-size: 20px !important;
                            font-weight: 500 !important;
                            transition: font-size 0.3s linear;
                        }

                        &:hover {
                            &::placeholder {
                                font-size: 15px !important;
                            }
                        }
                      
                    }

                    button {
                        width: 220px !important;
                        font: 400 25px 'Poppins-Regular', sans-serif !important;
                        padding: 14px 5px !important;
                        border-radius: 60px !important;
                    }
                }
            }

            .form-group-checkbox {
                border-radius: 60px;
                bottom: 23px;
                position: absolute;
                left: 275px;
                max-width: 726px;

                input[type='checkbox']:checked + label::before {
                    background-color: var(--color-neutral-lightest);
                    border: 1px solid var(--color-neutral-lightest);
                }

                label {
                    font-size: 15px;
                    padding-left: 60px;

                    &::before {
                        top: 0;
                        width: 34px;
                        height: 34px;
                        border: 1px solid var(--color-primary-light);
                        background: var(--color-primary-light);
                    }

                    &::after {
                        left: 8px;
                        width: 31px;
                        height: 30px;
                    }
                }
            }
        }

        .overlay {
            height: 75px;
            width: 250px;
        }
    }
}


@media screen and (min-width: 1200px) {
    .sign-up-form-klavio {
        min-height: 500px;

        &.container-xl {
            padding-top: 150px;
            padding-bottom: 160px;
        }
    }
}