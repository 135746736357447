.main-breadcrumb {
    position: fixed;
    top: var(--nav-bar-height);
    z-index: 210;
    width: 100%;
    padding: 10px 0;
    background-color: #ffffff90;

    .breadcrumb-list {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;

        li {
            padding: 0 0 0 4px;

            a, span {
                text-decoration: none;
                color: var(--color-primary);

                &:hover {
                    color: var(--color-primary);
                    text-decoration: underline;
                }
            }

            &.active {
                a, span {
                    color: var(--color-primary-light);

                    &:hover {
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }

            &:not(:last-child):after {
                content: '>';
            }
        }
    }
}