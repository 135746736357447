@import './common-variables.scss';

.back-to-top-btn {
    position: fixed;
    top: 75%;
    right: 15px;
    z-index: 9998;
    display: none;
    border: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    background: var(--color-accent-gold);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 5px #00000029;

    @media screen and (min-width: 1024px) {
        top: 80%;
    }

    svg {
        width: 50px;
        height: 50px;
    }

    &:hover {
        background: var(--color-primary);

        svg {
            fill: var(--color-accent-gold)
        }
    }

    &.show {
        display: flex;
    }

    &.btn-front-end {
        &:after {
            margin: 0 0 5px 5px;
        }
    }
}
